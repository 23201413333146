const Footer = () => {
  return (
    <footer className="mt-12 mb-0 py-4 bottom-0 bg-dark border-t-solid border-t-2 border-light">
      <p>contact: kraokir@gmail.com</p>
      <p>made with &#x2661; by Kraoki</p>
    </footer>
  );
};

export default Footer;
